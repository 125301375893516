<!--
 * @Author: 月魂
 * @Date: 2022-09-08 09:48:31
 * @LastEditTime: 2022-09-20 16:39:05
 * @LastEditors: 月魂
 * @Description: 专题指定剧目列表
 * @FilePath: \silk-road\src\views\activities\perform-list.vue
-->
<template>
  <div class="box">
    <div class="header">
      <img v-if="playType === 'lamei'" src="http://image.wearetogether.com.cn/8th/assets/list/onshow.png" alt="" class="header-img">
      <img v-else src="http://image.wearetogether.com.cn/8th/assets/list/header.png" alt="" class="header-img">
      <div class="header-text">
        <van-icon name="arrow-left" class="back" @click="handleBack" />
        <div v-show="playType !== 'lamei'" class="title">{{ title }}</div>
        <div v-show="playType !== 'lamei'" class="enTitle" v-if="enTitle">{{ enTitle }}</div>
      </div>
    </div>
    <!-- <div class="header">
      <div class="title">{{ this.$t('intro.name') }}</div>
      <div class="subTitle">{{ title }}</div>
    </div> -->
    
    <div class="list">
      <div class="item" @click="jump(perform.id)" v-for="perform in performList" :key="perform.id">
        <img class="top-img" :src="perform.imgUrl" alt="" width="100%">
        <div class="bottom">
          <div class="title">{{ perform.title }}</div>
          <div class="team">{{ perform.team }}</div>
          <div class="time">{{ perform.time }}</div>
          <div class="address">{{ perform.address }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon } from 'vant'
import { handleBack } from '../../utils/util'
Vue.use(Icon)

export default {
  name: 'SpecifyList',
  components: {
  },
  data() {
    return {
      title: '',
      enTitle: '',
      playType: '',
      performList: []
    }
  },
  computed: {
    getList: function() {
      return Object.values(this.$t('perform.list'))
    }
  },
  mounted() {
    this.title = this.$route.query.title
    this.playType = this.$route.query.playType
    this.enTitle = this.$route.query.enTitle
    // 此处根据playType和performType来确定是查询指定剧目类型，还是查询线上线下剧目
    if (this.$route.query.playType) {
      this.performList = this.getList.filter(item => item.playType && Object.values(item.playType).includes(this.$route.query.playType))
    } else if(this.$route.query.performType) {
      this.performList = this.getList.filter(item => item.type === this.$route.query.performType)
    }
    this.wxShare('', this.title);
  },
  methods: {
    handleBack() {
      handleBack()
    },
    jump(id) { // 此处需传入剧目id
      this.$router.push(`/perform-detail/${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #fcf8ed;
  .header {
    max-width: 1200PX;
    margin: auto;
    color: #fff;
    word-break: break-word;
    position: relative;
    .header-img {
      display: block;
      width: 100%;
    }
    .header-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      .back {
        position: absolute;
        top: 8px;
        left: 16px;
        width: 16px;
        font-size: 16px;
      }
      .title {
        font-family: SourceHanSerifCN-Bold;
        font-size: 34px;
        color: #FFF6E2;
        position: absolute;
        top: 24px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
      }
      .enTitle {
        font-family: Arial;
        text-align: center;
        font-size: 12px;
        color: #FFF6E3;
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  .mySwiper {
    max-width: 1200PX;
    margin: auto;
    .swiper {
      width: 100%;
    }
  }
  .list {
    max-width: 1200PX;
    margin: auto;
    display: flex;
    padding: 16px;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 100%;
      background-color: #fff;
      margin-top: 16px;
      border-radius: 10px;
      overflow: hidden;
      &:first-child {
        margin-top: 0;
      }
      .top-img {
        display: block;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
      }
      .bottom {
        padding: 14px 16px;
        display: flex;
        flex-wrap: wrap;
        font-family: SourceHanSansCN-Medium;
        .title {
          width: 100%;
          font-size: 18px;
          color: #4D4D4D;
        }
        .team {
          width: 100%;
          font-size: 12px;
          color: #999999;
          margin-top: 8px;
        }
        .time {
          font-size: 12px;
          margin-right: 8px;
          color: #999999;
          margin-top: 8px;
        }
        .address {
          font-size: 12px;
          color: #999999;
          margin-top: 8px;
        }
      }
    }
    @media (min-width: 1200PX) {
      padding: 0;
      margin-top: 10px;
      .item {
        width: 49%;
      }
    }
  }
}
</style>
